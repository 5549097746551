<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <card-initializing
        v-if="isInitializing"
        :text="`Loading Mortgage Deal ${ mortgage_deal_id }`"
      />
      <card-not-found
        v-else-if="!mortgageDealExists"
        :back-button-route="MORTGAGE_DEALS_ROUTE"
        :text="`Mortgage Deal Not Found (${mortgage_deal_id})`"
        back-button-tooltip="Mortgage Deal List"
      />
      <v-card
        v-else
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <card-toolbar
          :back-button-route="MORTGAGE_DEALS_ROUTE"
          :icon="MORTGAGE_DEAL_ICON"
          :tab="toolBarTab"
          :tabItems="toolBarTabItems"
          :title="`Mortgage Deal ${mortgage_deal_id}`"
          @refresh-data="refreshMortgageDeal()"
          @tab-change="toolBarTab = $event"
          back-button-tooltip="Mortgage Deal List"
          button-mode
          include-back
          include-help
          include-refresh
        />

        <v-tabs-items
          v-model="toolBarTab"
          class="px-2"
        >
          <v-tab-item :transition="false">
            <v-card
              class="mb-4 mt-2 pa-0"
              flat
            >
              <card-toolbar
                @collapse-all="panel = []"
                @expand-all="panel = panelsExpanded"
                button-mode
                class="mb-2"
                include-collapse
                include-expand
                title="Data"
              />
              <help-text :page="MORTGAGE_DEAL_VUE" />

              <v-expansion-panels
                v-model="panel"
                class=""
                multiple
                popout
              >
                <crud-expansion-panel
                  :disable-save="isMortgageDealSaveDisabled"
                  :help-page="MORTGAGE_DEAL_VUE_MORTGAGE_DEAL_EXPAND"
                  :loading="loading[MORTGAGE_DEAL]"
                  :readonly="!isMortgageDealEditing"
                  :saving="saving[MORTGAGE_DEAL]"
                  @cancel-edit="mortgageDeal.cancelEdit(MORTGAGE_DEAL)"
                  @enable-edit="mortgageDeal.enableEdit(MORTGAGE_DEAL)"
                  @save-data="updateMortgageDealData(MORTGAGE_DEAL, mortgageDeal[DATA][MORTGAGE_DEAL])"
                  type="Mortgage Deal"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isMortgageDealPanelTextDisplayed"
                      title="Mortgage Deal"
                      :text="mortgageDeal.getSummaryText(MORTGAGE_DEAL)"
                    />
                  </template>
                  <template v-slot:content>
                    <v-fade-transition>
                      <v-form
                        v-if="!loading[MORTGAGE_DEAL]"
                        v-model="validated[MORTGAGE_DEAL]"
                        @submit.prevent
                      >
                        <v-row v-for="(fieldGroup, i) in mortgageDealFieldGroups" :key="i">
                          <v-col
                            v-for="(field, j) in fieldGroup" :key="j"
                            dense
                            cols="12" :sm="field.smColumns" :md="field.mdColumns"
                          >
                            <v-text-field
                              v-if="field.fieldType === TEXT"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL][field.property]"
                              :clearable="isMortgageDealEditing"
                              :counter="field.counter"
                              dense
                              :prepend-icon="field.iconPrepend"
                              :readonly="!isMortgageDealEditing"
                              :rules="field.rules"
                              :label="field.label"
                            />
                            <v-select
                              v-else-if="field.fieldType === SELECT"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL][field.property]"
                              dense
                              :items="field.items"
                              :item-text="(field.textProperty !== null) ? field.textProperty : null"
                              :item-value="(field.textProperty !== null) ? field.textProperty : null"
                              :readonly="!isMortgageDealEditing"
                              :label="field.label"
                            />
                            <text-field-date-picker
                              v-else-if="field.fieldType === DATE"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL][field.property]"
                              dense
                              :clearable="isMortgageDealEditing"
                              :rules="field.rules"
                              :readonly="!isMortgageDealEditing"
                              :label="field.label"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-fade-transition>
                  </template>
                </crud-expansion-panel>

                <crud-expansion-panel
                  @cancel-edit="mortgageDeal.cancelEdit(MORTGAGE)"
                  @enable-edit="mortgageDeal.enableEdit(MORTGAGE)"
                  @save-data="updateMortgageDealData(MORTGAGE, mortgageDeal[DATA][MORTGAGE])"
                  :disable-save="isMortgageSaveDisabled"
                  :help-page="MORTGAGE_DEAL_VUE_MORTGAGE_EXPAND"
                  :loading="loading[MORTGAGE]"
                  :readonly="!isMortgageEditing"
                  :saving="saving[MORTGAGE]"
                  type="Mortgage"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isMortgagePanelTextDisplayed"
                      title="Mortgage"
                      :text="mortgageDeal.getSummaryText(MORTGAGE)"
                    />
                  </template>
                  <template v-slot:content>
                    <v-fade-transition>
                      <v-form
                        v-if="!loading[MORTGAGE]"
                        v-model="validated[MORTGAGE]"
                        @submit.prevent
                      >
                        <v-row v-for="(fieldGroup, i) in mortgageDeal.getFieldGroups(MORTGAGE)" :key="i">
                          <v-col
                            v-for="(field, j) in fieldGroup" :key="j"
                            dense
                            cols="12" :sm="field.smColumns" :md="field.mdColumns"
                          >
                            <v-text-field
                              v-if="field.fieldType === TEXT"
                              v-model="mortgageDeal[DATA][MORTGAGE][field.property]"
                              :clearable="isMortgageEditing"
                              :counter="field.counter"
                              dense
                              :readonly="!isMortgageEditing"
                              :rules="field.rules"
                              :label="field.label"
                            />
                            <v-select
                              v-else-if="field.fieldType === SELECT"
                              v-model="mortgageDeal[DATA][MORTGAGE][field.property]"
                              dense
                              :items="field.items"
                              :item-text="(field.textProperty !== null) ? field.textProperty : null"
                              :item-value="(field.textProperty !== null) ? field.textProperty : null"
                              :readonly="!isMortgageEditing"
                              :label="field.label"
                            />
                            <text-field-date-picker
                              v-else-if="field.fieldType === DATE"
                              v-model="mortgageDeal[DATA][MORTGAGE][field.property]"
                              dense
                              :clearable="isMortgageEditing"
                              :rules="field.rules"
                              :readonly="!isMortgageEditing"
                              :label="field.label"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-fade-transition>
                  </template>
                </crud-expansion-panel>

                <crud-expansion-panel
                  @cancel-edit="mortgageDeal.cancelEdit(MORTGAGE_DEAL_PROGRAM_CONTROL)"
                  @enable-edit="mortgageDeal.enableEdit(MORTGAGE_DEAL_PROGRAM_CONTROL)"
                  @save-data="updateMortgageDealData(MORTGAGE_DEAL_PROGRAM_CONTROL, mortgageDeal[DATA][MORTGAGE_DEAL_PROGRAM_CONTROL])"
                  :disable-save="isProgramControlSaveDisabled"
                  :help-page="MORTGAGE_DEAL_VUE_PROGRAM_CONTROL_EXPAND"
                  :loading="loading[MORTGAGE_DEAL_PROGRAM_CONTROL]"
                  :readonly="!isProgramControlEditing"
                  :saving="saving[MORTGAGE_DEAL_PROGRAM_CONTROL]"
                  type="Mortgage Deal Program Control"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isProgramControlPanelTextDisplayed"
                      title="Program Controls"
                      :text="mortgageDeal.getSummaryText(MORTGAGE_DEAL_PROGRAM_CONTROL)"
                    />
                  </template>
                  <template v-slot:content>
                    <v-fade-transition>
                      <v-form
                        v-if="!loading[MORTGAGE_DEAL_PROGRAM_CONTROL]"
                        v-model="validated[MORTGAGE_DEAL_PROGRAM_CONTROL]"
                        @submit.prevent
                      >
                        <v-row v-for="(fieldGroup, i) in mortgageDeal.getFieldGroups(MORTGAGE_DEAL_PROGRAM_CONTROL)" :key="i">
                          <v-col
                            v-for="(field, j) in fieldGroup" :key="j"
                            dense
                            cols="12" :sm="field.smColumns" :md="field.mdColumns"
                          >
                            <v-text-field
                              v-if="field.fieldType === TEXT"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL_PROGRAM_CONTROL][field.property]"
                              :clearable="isProgramControlEditing"
                              :counter="field.counter"
                              dense
                              :readonly="!isProgramControlEditing"
                              :rules="field.rules"
                              :label="field.label"
                            />
                            <v-select
                              v-else-if="field.fieldType === SELECT"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL_PROGRAM_CONTROL][field.property]"
                              dense
                              :items="field.items"
                              :item-text="(field.textProperty !== null) ? field.textProperty : null"
                              :item-value="(field.textProperty !== null) ? field.textProperty : null"
                              :readonly="!isProgramControlEditing"
                              :label="field.label"
                            />
                            <text-field-date-picker
                              v-else-if="field.fieldType === DATE"
                              v-model="mortgageDeal[DATA][MORTGAGE_DEAL_PROGRAM_CONTROL][field.property]"
                              dense
                              :clearable="isProgramControlEditing"
                              :rules="field.rules"
                              :readonly="!isProgramControlEditing"
                              :label="field.label"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-fade-transition>
                  </template>
                </crud-expansion-panel>

                <crud-expansion-panel
                  @cancel-edit="mortgageDeal.cancelEdit(MORTGAGE_PROPERTY)"
                  @enable-edit="mortgageDeal.enableEdit(MORTGAGE_PROPERTY)"
                  @save-data="updateMortgageDealData(MORTGAGE_PROPERTY, mortgageDeal[DATA][MORTGAGE_PROPERTY])"
                  :disable-save="isPropertySaveDisabled"
                  :help-page="MORTGAGE_DEAL_VUE_PROPERTY_EXPAND"
                  :loading="loading[MORTGAGE_PROPERTY]"
                  :readonly="!isPropertyEditing"
                  :saving="saving[MORTGAGE_PROPERTY]"
                  type="Mortgage Property"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isPropertyPanelTextDisplayed"
                      title="Mortgage Property"
                      :text="mortgageDeal.getSummaryText(MORTGAGE_PROPERTY)"
                    />
                  </template>
                  <template v-slot:content>
                    <v-fade-transition>
                      <v-form
                        v-if="!loading[MORTGAGE_PROPERTY]"
                        v-model="validated[MORTGAGE_PROPERTY]"
                        @submit.prevent
                      >
                        <v-row v-for="(fieldGroup, i) in mortgageDeal.getFieldGroups(MORTGAGE_PROPERTY)" :key="i">
                          <v-col
                            v-for="(field, j) in fieldGroup" :key="j"
                            dense
                            cols="12" :sm="field.smColumns" :md="field.mdColumns"
                          >
                            <v-text-field
                              v-if="field.fieldType === TEXT"
                              v-model="mortgageDeal[DATA][MORTGAGE_PROPERTY][field.property]"
                              :clearable="isPropertyEditing"
                              :counter="field.counter"
                              dense
                              :readonly="!isPropertyEditing"
                              :rules="field.rules"
                              :label="field.label"
                            />
                            <v-select
                              v-else-if="field.fieldType === SELECT"
                              v-model="mortgageDeal[DATA][MORTGAGE_PROPERTY][field.property]"
                              dense
                              :items="field.items"
                              :item-text="(field.textProperty !== null) ? field.textProperty : null"
                              :item-value="(field.textProperty !== null) ? field.textProperty : null"
                              :readonly="!isPropertyEditing"
                              :label="field.label"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-fade-transition>
                  </template>
                </crud-expansion-panel>

                <crud-expansion-panel
                  disable-toolbar
                  :loading="loading[MORTGAGE_DEAL_AGENT_ASSN]"
                  type="Mortgage Deal-Agent Association"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isAgentAssociationPanelTextDisplayed"
                      title="Associated Agents"
                      :text="mortgageDeal.getSummaryText(MORTGAGE_DEAL_AGENT_ASSN)"
                    />
                  </template>
                  <template v-slot:content>
                    <card-group-associations
                      :association-data="mortgageDeal[DATA][MORTGAGE_DEAL_AGENT_ASSN]"
                      :association-target-data="filteredAgentAssociations"
                      :create-function="createMortgageDealData"
                      :delete-function="deleteMortgageDealData"
                      :deleting="deleting[MORTGAGE_DEAL_AGENT_ASSN]"
                      :saving="saving[MORTGAGE_DEAL_AGENT_ASSN]"
                      @search-input="agentAssociationSearch = $event"
                      :type="MORTGAGE_DEAL_AGENT_ASSN"
                    />
                  </template>
                </crud-expansion-panel>

                <crud-expansion-panel
                  disable-toolbar
                  :loading="loading[MORTGAGE_DEAL_CLIENT_ASSN]"
                  type="Mortgage Deal-Client Association"
                >
                  <template v-slot:title>
                    <expansion-panel-title-with-text
                      :display="isClientAssociationPanelTextDisplayed"
                      title="Associated Clients"
                      :text="mortgageDeal.getSummaryText(MORTGAGE_DEAL_CLIENT_ASSN)"
                    />
                  </template>
                  <template v-slot:content>
                    <card-group-associations
                      :association-data="mortgageDeal[DATA][MORTGAGE_DEAL_CLIENT_ASSN]"
                      :association-target-data="filteredClientAssociations"
                      :create-function="createMortgageDealData"
                      :delete-function="deleteMortgageDealData"
                      :deleting="deleting[MORTGAGE_DEAL_CLIENT_ASSN]"
                      :saving="saving[MORTGAGE_DEAL_CLIENT_ASSN]"
                      @search-input="clientAssociationSearch = $event"
                      :type="MORTGAGE_DEAL_CLIENT_ASSN"
                    />
                  </template>
                </crud-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-tab-item>
          <v-tab-item :transition="false">
            <comment-list
              :comments="mortgageDeal[DATA][MORTGAGE_DEAL_COMMENT]"
              :create-function="createMortgageDealData"
              :model="mortgageDeal"
              :saving="saving[MORTGAGE_DEAL_COMMENT]"
              :type="MORTGAGE_DEAL_COMMENT"
              class="my-4 pa-0"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, differenceWith, isEqual, isNil, orderBy, range } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { ACTIONS, EXPANDS, ICONS, ROUTES, VALID_TABLES, VUES, MESSAGE_TYPE, DATA, } from '../constants'
import { isTypeIterable, TYPE as MORTGAGE_DEAL_TYPE, PROPERTY as MORTGAGE_DEAL_PROPERTY } from '../models/dto/MortgageDeal'
import { TYPE as AGENT_TYPE } from '../models/dto/Agent'
import { TYPE as CLIENT_TYPE } from '../models/dto/Client'
import { FIELD_TYPES } from '../models/field_configs/FieldConfig'
import { now, transformError } from '../services/utility.service'

export default {
  components: {
    CardGroupAssociations: () => import('../components/layout/CardGroupAssociations.vue'),
    CardInitializing: () => import('../components/layout/cards/CardInitializing.vue'),
    CardNotFound: () => import('../components/layout/cards/CardNotFound.vue'),
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    CommentList: () => import('../components/layout/CommentList.vue'),
    CrudExpansionPanel: () => import('../components/layout/CrudExpansionPanel.vue'),
    ExpansionPanelTitleWithText: () => import('../components/layout/ExpansionPanelTitleWithText.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
    TextFieldDatePicker: () => import('../components/input/TextFieldDatePicker.vue'),
  },
  data: () => ({
    mortgage_deal_id: null,   // Mortgage deal ID of this MortgageDeal.
    mortgageDeal: {},
    mortgageDealExists: true, // Flag that indicates if this MortgageDeal exists.

    isInitializing: true, // Has this component page been initialized?

    agentAssociationSearch: '',  // Search field for the mortgage-deal-agent associations.
    clientAssociationSearch: '', // Search field for the mortgage-deal-client associations.

    panelCount: 0,      // Number of expansion panels.
    panel: [],          // Expansion panel state (active panels).
    panelsExpanded: [], // Array for "all panels active".

    toolBarTab: 0, // Currenty selected tab.

    // Lists of data used by client data-types.
    lists: {
      [VALID_TABLES.PROVINCE]: [],
      [AGENT_TYPE.AGENT]: [],
      [CLIENT_TYPE.CLIENT]: [],
    },

    // Stores whether a data-type is currently "deleting" data.
    deleting: {
      [MORTGAGE_DEAL_TYPE.MORTGAGE]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT]: false ,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: false
    },

    // Stores whether a data-type is currently "loading" data.
    loading: {
      [MORTGAGE_DEAL_TYPE.MORTGAGE]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT]: false ,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: false
    },

    // Stores whether a data-type is currently "saving" data.
    saving: {
      [MORTGAGE_DEAL_TYPE.MORTGAGE]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT]: false ,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: false
    },

    // Stores whether the fields for a client data-type are currently valid.
    validated: {
      [MORTGAGE_DEAL_TYPE.MORTGAGE]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT]: false ,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY]: false,
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: false
    },
  }),
  computed: {
    ...mapGetters({
      getBasicAgentProfiles: 'agent/getBasicAgentProfiles',
      getCachedClientList: 'client/getCachedClientList',
      getValidData: 'valid/getData',
      user: 'user/getUser',
    }),

    filteredAgentAssociations () {
      const filteredBySearch = orderBy(this.lists[AGENT_TYPE.AGENT].filter(item => {
        return item.agent_id.toString().includes(this.agentAssociationSearch.toLowerCase()) ||
          item.agent_name.toLowerCase().includes(this.agentAssociationSearch.toLowerCase())
      }), 'agent_id')

      const filteredByCurrent = differenceWith(filteredBySearch, this.mortgageDeal.getData(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN), (a, b) => {
        return isEqual(a.agent_id, b.agent_id) && isEqual(a.profile_id, b.profile_id)
      })

      return filteredByCurrent
    },

    filteredClientAssociations () {
      const filteredBySearch = orderBy(this.lists[CLIENT_TYPE.CLIENT].filter(item => {
        return item.client_id.toString().includes(this.clientAssociationSearch.toLowerCase()) ||
          (`${item.first_name} ${item.surname}`).toLowerCase().includes(this.clientAssociationSearch.toLowerCase())
      }), 'client_id')

      const filteredByCurrent = differenceWith(filteredBySearch, this.mortgageDeal.getData(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN), (a, b) => {
        return isEqual(a.client_id, b.client_id)
      })

      return filteredByCurrent
    },

    isMortgageEditing () {
      return this.mortgageDeal.isEditing(MORTGAGE_DEAL_TYPE.MORTGAGE)
    },
    isMortgageDealEditing () {
      return this.mortgageDeal.isEditing(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL)
    },
    isProgramControlEditing () {
      return this.mortgageDeal.isEditing(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL)
    },
    isPropertyEditing () {
      return this.mortgageDeal.isEditing(MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY)
    },

    isMortgageSaveDisabled () {
      this.mortgageDeal[ACTIONS.EDIT][MORTGAGE_DEAL_TYPE.MORTGAGE] // This is here to force a recalculation on edit.

      return this.saving[MORTGAGE_DEAL_TYPE.MORTGAGE] || !this.validated[MORTGAGE_DEAL_TYPE.MORTGAGE] ||
        !this.mortgageDeal.hasDataChanged(MORTGAGE_DEAL_TYPE.MORTGAGE)
    },
    isMortgageDealSaveDisabled () {
      this.mortgageDeal[ACTIONS.EDIT][MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL] // This is here to force a recalculation on edit.

      return this.saving[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL] || !this.validated[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL] ||
        !this.mortgageDeal.hasDataChanged(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL)
    },
    isProgramControlSaveDisabled () {
      this.mortgageDeal[ACTIONS.EDIT][MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL] // This is here to force a recalculation on edit.

      return this.saving[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL] || !this.validated[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL] ||
        !this.mortgageDeal.hasDataChanged(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL)
    },
    isPropertySaveDisabled () {
      this.mortgageDeal[ACTIONS.EDIT][MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY] // This is here to force a recalculation on edit.

      return this.saving[MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY] || !this.validated[MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY] ||
        !this.mortgageDeal.hasDataChanged(MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY)
    },

    isMortgageDealPanelTextDisplayed () {
      return !this.panel.includes(0)
    },
    isMortgagePanelTextDisplayed () {
      return !this.panel.includes(1)
    },
    isProgramControlPanelTextDisplayed () {
      return !this.panel.includes(2)
    },
    isPropertyPanelTextDisplayed () {
      return !this.panel.includes(3)
    },
    isAgentAssociationPanelTextDisplayed () {
      return !this.panel.includes(4)
    },
    isClientAssociationPanelTextDisplayed () {
      return !this.panel.includes(5)
    },

    mortgageDealFieldGroups () {
      let fieldGroups = cloneDeep(this.mortgageDeal.getFieldGroups(MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL))
      fieldGroups[0].shift()
      return fieldGroups
    },

    apiCallMetadata () {
      // Metadata required by API calls.
      return { operator_id: this.user.id, dlc: now(), }
    },

    toolBarTabItems () {
      return [
        {
          tooltip: 'Data',
          icon: ICONS.DATA,
        },
        {
          tooltip: 'Comments',
          icon: ICONS.COMMENT,
          displayBadge: true,
          badgeContent: this.mortgageDeal.getCommentCount(),
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchBasicAgentProfiles: 'agent/fetchBasicAgentProfiles',
      fetchClients: 'client/fetchClients',
      fetchMortgageDeal: 'mortgageDeal/fetchMortgageDeal',

      createData: 'mortgageDeal/createData',
      deleteData: 'mortgageDeal/deleteData',
      updateData: 'mortgageDeal/updateData',

      enqueueMessage: 'message/enqueueMessage',
      initValidStore: 'valid/initStore',
    }),
    ...mapMutations({
      cacheMortgageDeal: 'mortgageDeal/cacheMortgageDeal',
      agentRemoveFromCache: 'agent/removeFromCache',
      clientRemoveFromCache: 'client/removeFromCache',
    }),
    /**
     * Send an API request to create data from the data-type and (optional) iterable item.
     * @param {string} type MortgageDeal.TYPE
     * @param {*} item Item from an iterable MortgageDeal.TYPE
     */
    createMortgageDealData (type, item = null) {
      this.saving[type] = true

      let payload = {
        ...(item) ? item : this.mortgageDeal.getData(type),
        ...this.apiCallMetadata,
      }

      // Mortgage deals are actually two data-types combined, mortgages and mortgage deals. This handles
      // acquiring the primary key for the request.
      if (type === MORTGAGE_DEAL_TYPE.MORTGAGE || type === MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY) {
        payload.mortgage_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_ID)
      }
      else {
        payload.mortgage_deal_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_DEAL_ID)
      }

      this.createData({ type, payload })
        .then(data => {
          if (isTypeIterable(type)) {
            if (type === MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT) {
              // Mortgage deals are sorted in descending order by date-time, so we add this data to the front of the array.
              this.mortgageDeal.prependData(data, type)
            }
            else {
              this.mortgageDeal.appendData(data, type)
            }
          }
          else {
            this.mortgageDeal.setData(data, type)
          }
          this.mortgageDeal.disableEdit(type)
          this.cacheMortgageDeal({ mortgageDeal: this.mortgageDeal, mortgage_deal_id: payload.mortgage_deal_id })
          
          // When adding associations, ensure that you remove any associated objects so the new associations are fetched on load.
          switch (type) {
            case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
              this.clientRemoveFromCache({ client_id: payload.client_id })
              break
            case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
              this.agentRemoveFromCache({ agent_id: payload.agent_id })
              break
          }

          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.saving[type] = false
        })
    },
    /**
     * Send an API request to delete data from the data-type and (optional) iterable item.
     * @param {string} type MortgageDeal.TYPE
     * @param {*} item Item from an iterable MortgageDeal.TYPE
     */
    deleteMortgageDealData (type, item) {
      this.deleting[type] = true

      const payload = {
        ...(item) ? item : this.mortgageDeal.getData(type),
      }

      let config = {
        type,
        id: payload.id
      }

      // Mortgage deals are actually two data-types combined, mortgages and mortgage deals. This handles
      // acquiring the primary key for the request.
      if (type === MORTGAGE_DEAL_TYPE.MORTGAGE || type === MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY) {
        config.mortgage_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_ID)
      }
      else {
        config.mortgage_deal_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_DEAL_ID)
      }

      this.deleteData(config)
        .then(() => {
          if (isTypeIterable(type)) {
            this.mortgageDeal.deleteData(item, type)
          }
          else {
            this.mortgageDeal.setToDefaultData(type)
          }

          this.mortgageDeal.disableEdit(type)
          this.cacheMortgageDeal({ mortgageDeal: this.mortgageDeal, mortgage_deal_id: payload.mortgage_deal_id })

          // When adding associations, ensure that you remove any associated objects so the new associations are fetched on load.
          switch (type) {
            case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
              this.clientRemoveFromCache({ client_id: payload.client_id })
              break
            case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
              this.agentRemoveFromCache({ agent_id: payload.agent_id })
              break
          }

          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.deleting[type] = false
        })
    },
    /**
     * Initialize the constants used in the HTML of this view.
     */
    initTemplateConstants () {
      this.MORTGAGE_DEAL_VUE_AGENT_ASSN_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_AGENT_ASSN
      this.MORTGAGE_DEAL_VUE_CLIENT_ASSN_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_CLIENT_ASSN
      this.MORTGAGE_DEAL_VUE_COMMENT_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_COMMENT
      this.MORTGAGE_DEAL_VUE_MORTGAGE_DEAL_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_MORTGAGE_DEAL
      this.MORTGAGE_DEAL_VUE_MORTGAGE_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_MORTGAGE
      this.MORTGAGE_DEAL_VUE_PROPERTY_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_PROPERTY
      this.MORTGAGE_DEAL_VUE_PROGRAM_CONTROL_EXPAND = EXPANDS.MORTGAGE_DEAL_VUE_PROGRAM_CONTROL

      this.DATE = FIELD_TYPES.DATE
      this.SELECT = FIELD_TYPES.SELECT
      this.TEXT = FIELD_TYPES.TEXT

      this.MORTGAGE_DEAL_ICON = ICONS.MORTGAGE_DEAL

      this.MORTGAGE_DEALS_ROUTE = ROUTES.MORTGAGE_DEALS

      this.MORTGAGE = MORTGAGE_DEAL_TYPE.MORTGAGE
      this.MORTGAGE_DEAL = MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL
      this.MORTGAGE_DEAL_AGENT_ASSN = MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN
      this.MORTGAGE_DEAL_CLIENT_ASSN = MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN
      this.MORTGAGE_DEAL_COMMENT = MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT
      this.MORTGAGE_DEAL_PROGRAM_CONTROL = MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL
      this.MORTGAGE_PROPERTY = MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY

      this.MORTGAGE_DEAL_VUE = VUES.MORTGAGE_DEAL

      this.DATA = DATA
    },
    initExpansionPanel () {
      this.panelCount = 5
      this.panelsExpanded = range(this.panelCount)
      this.panel = []
    },
    /**
     * Initialize any stores that are used within the template.
     */
    async initMortgageDeal () {
      let results = null
      try {
        results = await Promise.all([
          this.initValidStore(),
          this.fetchMortgageDeal({ mortgage_deal_id: this.mortgage_deal_id })
        ])
      }
      catch (error) {
        this.mortgageDealExists = false
        this.errorMessage(`Failed to initialize mortgage deal. ${transformError(error)}`)
      }

      // Setup the mortgage deal and add any valid table dependencies to its metadata.
      this.mortgageDeal = results[1]
      this.mortgageDealExists = !isNil(this.mortgageDeal)

      this.fetchBasicAgentProfiles()
        .then(() => {
          this.lists[AGENT_TYPE.AGENT] = this.getBasicAgentProfiles
        })
        .catch(error => {
          this.errorMessage(`Failed to fetch basic agent profiles. ${transformError(error)}`)
        })
      
      this.fetchClients({ force: false })
        .then(list => {
          this.lists[CLIENT_TYPE.CLIENT] = list
        })
        .catch(error => {
          this.errorMessage(`Failed to fetch client list. ${transformError(error)}`)
        })
      this.isInitializing = false
    },
    async refreshMortgageDeal () {
      this.isInitializing = true
      try {
        this.mortgageDeal = await this.fetchMortgageDeal({ mortgage_deal_id: this.mortgage_deal_id, force: true })
        this.mortgageDealExists = !isNil(this.mortgageDeal)
      }
      catch (error) {
        this.mortgageDealExists = false
        this.errorMessage(`Failed to fetch mortgage deal. ${transformError(error)}`)
      }
      this.isInitializing = false
    },
    /**
     * Send an update API request for the data-type and payload.
     * @param {string} type MortgageDeal.TYPE
     */
    async updateMortgageDealData (type) {
      this.saving[type] = true

      const payload = {
        ...this.mortgageDeal.getData(type),
        ...this.apiCallMetadata,
      }

      // Mortgage deals are actually two data-types combined, mortgages and mortgage deals. This handles
      // acquiring the primary key for the request.
      if (type === MORTGAGE_DEAL_TYPE.MORTGAGE || type === MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY) {
        payload.mortgage_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_ID)
      }
      else {
        payload.mortgage_deal_id = this.mortgageDeal.getMortgageDealProperty(MORTGAGE_DEAL_PROPERTY.MORTGAGE_DEAL_ID)
      }

      // Send update call.
      let updatedData = null
      try {
        updatedData = await this.updateData({ type, payload })
        this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })

        // If it's an iterable type, fetch a new list first.
        if (isTypeIterable(type)) {
          try {
            updatedData = await this.fetchList({ type: type, mortgage_deal_id: payload.mortgage_deal_id, })
          }
          catch (error) {
            this.errorMessage(`Error fetching replacement data for the '${type}' data iterable: ${error}`)
            throw error
          }
        }
        
        this.mortgageDeal.setData(updatedData, type)
        this.cacheMortgageDeal({ mortgageDeal: this.mortgageDeal, mortgage_deal_id: payload.mortgage_deal_id })

        this.mortgageDeal.disableEdit(type)
      }
      catch (error) {
        this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
      }

      this.saving[type] = false
    },
  },
  async created () {
    this.isInitializing = true
    this.mortgage_deal_id = this.$route.params.id

    this.initTemplateConstants()
    this.initExpansionPanel()
    this.initMortgageDeal()
  }
}
</script>

<style scoped>
button {
  outline: none;
}
div.v-list {
  cursor: pointer;
}
</style>